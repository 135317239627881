import { useEffect } from 'react';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';

import '@assets/css/tailwind.css';
import { appWithTranslation } from 'next-i18next';


const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    // Automatically blurring active elements on route changes
    const handleRouteChange = () => {
      const activeElement = document.activeElement;
      if (activeElement instanceof HTMLElement) {
        activeElement.blur();
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    // Cleanup event listener on component unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // Apply custom class to body if provided in pageProps
    document.body.className = pageProps.className as string || '';
  }, [pageProps.className]);

  return <Component {...pageProps} />;
};

export default appWithTranslation(MyApp);
